import React, { useEffect } from "react";

const MessageModal = ({ type, message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);
    return () => {
      clearTimeout(timer);
      document.body.classList.add("no-scroll");
    };
  }, [onClose]);
  const messageStyle = type === "error" ? { color: "red" } : { color: "green" };
  return (
    <div className="message-modal-overlay">
      <div className="message-modal-content">
        <div className="modal-content">
          {Array.isArray(message) ? (
            message.map((msg, index) => (
              <p
                key={index}
                className={`modal-message ${
                  type === "error" ? "error-message" : "success-message"
                }`}
              >
                {msg}
              </p>
            ))
          ) : (
            <p className="modal-message ">{message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
